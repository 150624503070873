import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBBtn,
    MDBInput,
} from 'mdb-react-ui-kit';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import { api } from '../services';

function CategoryOverview() {
    const [categories, setCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredCategories, setFilteredCategories] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get('/categories', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setCategories(response.data);
            setFilteredCategories(response.data);
        } catch (err) {
            console.error('Error fetching categories:', err.message);
        }
    };

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        if (query === '') {
            setFilteredCategories(categories);
        } else {
            setFilteredCategories(
                categories.filter((category) =>
                    category.name.en.toLowerCase().includes(query) ||
                    category.name.ar.toLowerCase().includes(query)
                )
            );
        }
    };

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area">
                    <h2 className="mb-4">Category Overview</h2>
                    <MDBRow className="mb-4">
                        <MDBCol md="6">
                            <MDBInput
                                type="text"
                                placeholder="Search Categories"
                                value={searchQuery}
                                onChange={handleSearch}
                            />
                        </MDBCol>
                        <MDBCol md="6" className="text-end">
                            <MDBBtn onClick={() => navigate('/add-category')} color="primary">
                                Add Category
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                    <MDBTable responsive>
                        <MDBTableHead>
                            <tr>
                                <th>#</th>
                                <th>Name (EN)</th>
                                <th>Name (AR)</th>
                                <th>Index</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {filteredCategories.map((category, index) => (
                                <tr key={category._id}>
                                    <td>{index + 1}</td>
                                    <td>{category.name.en}</td>
                                    <td>{category.name.ar}</td>
                                    <td>{category.index}</td>
                                </tr>
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                </MDBContainer>
            </div>
        </>
    );
}

export default CategoryOverview;
