import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ListGroup, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faReceipt,
  faFolder,
  faBoxes,
  faFileInvoiceDollar,
  faAngleDown,
  faAngleUp,
  faUsers,
  faCogs, // Icon for App Management
  faCartPlus,
} from '@fortawesome/free-solid-svg-icons';

export default function SideNavbar() {
  const location = useLocation();
  const [collapseCatalog, setCollapseCatalog] = useState(false);
  const [collapseExpenses, setCollapseExpenses] = useState(false);

  // Retrieve user type from localStorage
  const userType = localStorage.getItem('user_type');

  const isActive = (path) => location.pathname === path;

  const toggleCatalog = () => setCollapseCatalog(!collapseCatalog);
  const toggleExpenses = () => setCollapseExpenses(!collapseExpenses);

  // Determine the dashboard path based on user type
  const dashboardPath = userType === 'admin' ? '/admin/dashboard' : '/dashboard';

  // Menu Items Configuration
  const menuItems = [
    {
      title: 'Dashboard',
      path: dashboardPath,
      icon: faTachometerAlt,
      forAdmin: true,
      forUser: true,
    },
    {
      title: 'User List',
      path: '/admin/user-list',
      icon: faUsers,
      forAdmin: true,
    },
    {
      title: 'App Management',
      path: '/admin/available-apps', // New Admin App Management Route
      icon: faCogs,
      forAdmin: true,
    },
    {
      title: 'Orders',
      path: '/orders',
      icon: faReceipt,
      forUser: true,
    },
    {
      title: 'Inventory',
      path: '/inventory',
      icon: faBoxes,
      forUser: true,
    },
    {
      title: 'Stores & Branches',
      path: '/store&branches-overview',
      icon: faBoxes,
      forUser: true,
    },
    {
      title: 'Apps Management',
      path: '/apps-management',
      icon: faBoxes,
      forUser: true,
    },
    {
      title: 'Products',
      path: '/products-listing',
      icon: faCartPlus, // Icon for Products
      forUser: true,
    },
  ];

  return (
    <div className="position-sticky shadow-0 border sidebar">
      <ListGroup className="mx-3 mt-4">
        {menuItems.map((item) =>
          (userType === 'admin' && item.forAdmin) || (userType !== 'admin' && item.forUser) ? (
            <Link to={item.path} key={item.title} className="d-block">
              <ListGroup.Item active={isActive(item.path)} className="border-0 border-bottom rounded">
                <FontAwesomeIcon icon={item.icon} className="me-3" />
                {item.title}
              </ListGroup.Item>
            </Link>
          ) : null
        )}

        {userType !== 'admin' && (
          <>
            {/* Catalog Management Dropdown */}
            <ListGroup.Item
              action
              onClick={toggleCatalog}
              className="border-0 border-bottom rounded d-flex justify-content-between align-items-center"
              style={{ cursor: 'pointer' }}
            >
              <div>
                <FontAwesomeIcon icon={faFolder} className="me-3" />
                Catalog Management
              </div>
              <FontAwesomeIcon icon={collapseCatalog ? faAngleUp : faAngleDown} />
            </ListGroup.Item>

            <Collapse in={collapseCatalog}>
              <div>
              <Link to="/category-overview" className="d-block">
                  <ListGroup.Item
                    active={isActive('/category-overview')}
                    className="border-0 border-bottom rounded ps-5"
                  >
                    Categories
                  </ListGroup.Item>
                </Link>
                <Link to="/catalog-overview" className="d-block">
                  <ListGroup.Item
                    active={isActive('/catalog-overview')}
                    className="border-0 border-bottom rounded ps-5"
                  >
                    Catalog Overview
                  </ListGroup.Item>
                </Link>
                <Link to="/catalog/items-list" className="d-block">
                  <ListGroup.Item
                    active={isActive('/catalog/items-list')}
                    className="border-0 border-bottom rounded ps-5"
                  >
                    Items
                  </ListGroup.Item>
                </Link>
                <Link to="/catalog/apps-list" className="d-block">
                  <ListGroup.Item
                    active={isActive('/catalog/apps-list')}
                    className="border-0 border-bottom rounded ps-5"
                  >
                    Apps
                  </ListGroup.Item>
                </Link>
              </div>
            </Collapse>

            {/* Expenses & Reports Dropdown */}
            <ListGroup.Item
              action
              onClick={toggleExpenses}
              className="border-0 border-bottom rounded d-flex justify-content-between align-items-center"
              style={{ cursor: 'pointer' }}
            >
              <div>
                <FontAwesomeIcon icon={faFileInvoiceDollar} className="me-3" />
                Expenses & Reports
              </div>
              <FontAwesomeIcon icon={collapseExpenses ? faAngleUp : faAngleDown} />
            </ListGroup.Item>

            <Collapse in={collapseExpenses}>
              <div>
                <Link to="/expenses" className="d-block">
                  <ListGroup.Item
                    active={isActive('/expenses')}
                    className="border-0 border-bottom rounded ps-5"
                  >
                    All Expenses
                  </ListGroup.Item>
                </Link>
                <Link to="/reports" className="d-block">
                  <ListGroup.Item
                    active={isActive('/reports')}
                    className="border-0 border-bottom rounded ps-5"
                  >
                    Reports
                  </ListGroup.Item>
                </Link>
              </div>
            </Collapse>
          </>
        )}
      </ListGroup>
    </div>
  );
}
