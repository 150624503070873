import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdb-react-ui-kit';
import { api } from '../services';

function ProductListing() {
  const [viewMode, setViewMode] = useState('list');
  const [productsData, setProductsData] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState('Select Branch');
  const [selectedBranchId, setSelectedBranchId] = useState(null);
  const [storeId, setStoreId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchStoreId(); // Fetch store ID on mount
  }, []);

  useEffect(() => {
    if (storeId) {
      fetchBranches(); // Fetch branches after store ID is fetched
    }
  }, [storeId]);

  useEffect(() => {
    if (selectedBranchId) {
      fetchProducts(selectedBranchId); // Fetch products for the selected branch
    }
  }, [selectedBranchId]);

  const fetchStoreId = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.get('/stores/my', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.stores && response.data.stores.length > 0) {
        setStoreId(response.data.stores[0]._id);
      }
    } catch (error) {
      console.error('Error fetching store ID:', error.response?.data || error.message);
    }
  };

  const fetchBranches = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.get(`/stores/${storeId}/branches`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBranches(response.data.branches || []);
    } catch (error) {
      console.error('Error fetching branches:', error.response?.data || error.message);
    }
  };

  const fetchProducts = async (branchId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.get(`/branches/${branchId}/products`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProductsData(response.data.products || []);
    } catch (error) {
      console.error('Error fetching products:', error.response?.data || error.message);
    }
  };

  const handleBranchSelect = (branch) => {
    setSelectedBranch(branch.name);
    setSelectedBranchId(branch._id);
  };

  const handleViewModeChange = (mode) => setViewMode(mode);

  const handleSearchChange = (e) => setSearchQuery(e.target.value);

  const handleAddProductClick = () => navigate('/add-product');

  const handleEditProductClick = (product) => {
    navigate(`/edit-product/${product.product_id}`);
  };

  const filteredProducts = productsData.filter((product) =>
    product.name.en.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <TopNavbar />
      <div className="d-flex dashboard-layout">
        <SideNavbar />
        <MDBContainer fluid className="p-4 content-area flex-grow-1">
          <h2 className="mb-4">Product Listing</h2>

          {/* Branch Selection */}
          <MDBRow className="mb-4">
            <MDBCol md="6">
              <label>Select Branch</label>
              <MDBDropdown>
                <MDBDropdownToggle color="light">{selectedBranch}</MDBDropdownToggle>
                <MDBDropdownMenu>
                  {branches.map((branch) => (
                    <MDBDropdownItem
                      key={branch._id}
                      onClick={() => handleBranchSelect(branch)}
                    >
                      {branch.name}
                    </MDBDropdownItem>
                  ))}
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBCol>
          </MDBRow>

          {/* Statistics Section */}
          <MDBRow className="mb-4 g-4">
            <MDBCol md="4">
              <MDBCard className="stat-card h-100">
                <MDBCardBody>
                  <h6>Total Products</h6>
                  <h3 className="mb-0">{productsData.length}</h3>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>

          {/* Quick Actions */}
          <MDBRow className="mb-4">
            <h6>Quick Actions</h6>
            <MDBCol>
              <MDBBtn
                color="primary"
                className="quick-action-btn"
                onClick={handleAddProductClick}
              >
                Add New Product
              </MDBBtn>
            </MDBCol>
          </MDBRow>

          {/* Search and View Toggle */}
          <MDBRow className="mb-4 d-flex align-items-center">
            <MDBCol md="6">
              <MDBInput
                type="text"
                placeholder="Search Products"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </MDBCol>
            <MDBCol md="6" className="d-flex justify-content-end align-items-center">
              <div className="ms-3">
                <MDBBtn
                  color="light"
                  onClick={() => handleViewModeChange('list')}
                  active={viewMode === 'list'}
                >
                  List
                </MDBBtn>
                <MDBBtn
                  color="light"
                  onClick={() => handleViewModeChange('grid')}
                  active={viewMode === 'grid'}
                >
                  Grid
                </MDBBtn>
              </div>
            </MDBCol>
          </MDBRow>

          {/* Product Content */}
          {viewMode === 'grid' ? (
            <MDBRow>
              {filteredProducts.map((product) => (
                <MDBCol md="4" key={product.product_id}>
                  <MDBCard className="order-card h-100">
                    <MDBCardBody className="position-relative text-center">
                      <img
                        src={product.image_path || 'https://via.placeholder.com/150'}
                        alt={product.name.en}
                        className="img-fluid mb-3"
                        style={{ maxHeight: '150px', objectFit: 'cover' }}
                      />
                      <h5 className="mb-1">{product.name.en}</h5>
                      <small className="text-muted">Price: ${product.product_price}</small>
                      <div className="text-center my-3">
                        <h6>Calories: {product.calories}</h6>
                      </div>
                      <MDBBtn
                        color="secondary"
                        size="sm"
                        onClick={() => handleEditProductClick(product)}
                      >
                        Edit
                      </MDBBtn>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              ))}
            </MDBRow>
          ) : (
            <MDBTable align="middle" responsive>
              <MDBTableHead className="table-header">
                <tr>
                  <th className="custom-th">#</th>
                  <th className="custom-th">Product Image</th>
                  <th className="custom-th">Product Name</th>
                  <th className="custom-th">Price</th>
                  <th className="custom-th">Calories</th>
                  <th className="custom-th">Actions</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody className="table-row">
                {filteredProducts.map((product, index) => (
                  <tr key={product.product_id}>
                    <td>{index + 1}</td>
                    <td>
                      <img
                        src={product.image_path || 'https://via.placeholder.com/100'}
                        alt={product.name.en}
                        className="img-fluid"
                        style={{ maxHeight: '50px', objectFit: 'cover' }}
                      />
                    </td>
                    <td>{product.name.en}</td>
                    <td>${product.product_price}</td>
                    <td>{product.calories}</td>
                    <td>
                      <MDBBtn
                        color="secondary"
                        size="sm"
                        onClick={() => handleEditProductClick(product)}
                      >
                        Edit
                      </MDBBtn>
                    </td>
                  </tr>
                ))}
              </MDBTableBody>
            </MDBTable>
          )}
        </MDBContainer>
      </div>
    </>
  );
}

export default ProductListing;
