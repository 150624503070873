import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logos/logo.png';
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBInput,
    MDBCardFooter,
    MDBCardHeader,
} from 'mdb-react-ui-kit';
import { api } from '../services';

function Register() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();

    const validateForm = () => {
        const errors = {};
        if (!email) errors.email = "Email address is required.";
        if (!phone) errors.phone = "Phone number is required.";
        if (!password) errors.password = "Password is required.";
        if (!confirmPassword) errors.confirmPassword = "Confirm Password is required.";
        if (password && confirmPassword && password !== confirmPassword) {
            errors.confirmPassword = "Passwords do not match.";
        }
        return errors;
    };

    const handleRegister = async () => {
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }
        setFormErrors({}); // Clear form errors if valid
    
        try {
            const response = await api.post('/register', { email, phone, password, user_type: 'company' });
            navigate('/'); // Navigate to login or dashboard
        } catch (error) {
            const errorMessage = error.response?.data?.message || "An error occurred during registration. Please try again.";
            console.error("Error during registration:", errorMessage);
    
            // Display specific errors for email or phone
            if (error.response?.data?.message === 'User already registered.') {
                setError("User already registered.");
            } else if (error.response?.data?.message === 'User already registered.') {
                setError("User already registered.");
            } else {
                setError(errorMessage);
            }
        }
    };
    

    return (
        <MDBContainer 
            fluid 
            className="d-flex justify-content-center align-items-center px-3 py-5"
            style={{ minHeight: "100vh", backgroundColor: "#f8f9fa" }}
        >
            <MDBCard 
                className="w-100 shadow-sm" 
                style={{ maxWidth: "500px", borderRadius: "15px" }}
            >
                <MDBCardHeader className="text-center p-3">
                    <MDBCardImage 
                        src={logo} 
                        alt="logo" 
                        className="img-fluid" 
                        style={{ maxWidth: "150px" }} 
                    />
                </MDBCardHeader>

                <MDBCardBody className="p-4">
                    <MDBInput
                        wrapperClass='mb-4'
                        label='Email address'
                        id='register-email'
                        type='email'
                        size="lg"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {formErrors.email && <p className="text-danger mb-3">{formErrors.email}</p>}

                    <MDBInput
                        wrapperClass='mb-4'
                        label='Phone Number'
                        id='register-phone'
                        type='tel'
                        size="lg"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    {formErrors.phone && <p className="text-danger mb-3">{formErrors.phone}</p>}

                    <MDBInput
                        wrapperClass='mb-4'
                        label='Password'
                        id='register-password'
                        type='password'
                        size="lg"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {formErrors.password && <p className="text-danger mb-3">{formErrors.password}</p>}

                    <MDBInput
                        wrapperClass='mb-4'
                        label='Confirm Password'
                        id='confirm-password'
                        type='password'
                        size="lg"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {formErrors.confirmPassword && <p className="text-danger mb-3">{formErrors.confirmPassword}</p>}

                    {error && <p className="text-danger text-center mb-4">{error}</p>}
                    <div className="text-center">
                        <MDBBtn 
                            className="mb-3 w-100 text-uppercase" 
                            size='lg' 
                            onClick={handleRegister}
                            style={{ backgroundColor: "#004080" }}
                        >
                            Register
                        </MDBBtn>
                    </div>
                    <p className="text-center text-muted mb-0">
                        Already have an account? 
                        <a href="/?login=true" className="text-primary ms-1">Login here</a>
                    </p>
                </MDBCardBody>

                <MDBCardFooter className='text-center'>
                    <small>
                        <a href="#!" className="text-muted me-1">Terms of use</a> |
                        <a href="#!" className="text-muted ms-1">Privacy policy</a>
                    </small>
                </MDBCardFooter>
            </MDBCard>
        </MDBContainer>
    );
}

export default Register;
