// App.js
import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import Home from './components/Home';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import CatalogOverview from './components/CatalogOverview';
import EditCatalog from './components/EditCatalog';
import AdminLogin from './components/Admin/Auth/AdminLogin';
import UserLogin from './components/UserLogin';
import AdminDashboard from './components/Admin/AdminDashboard';
import AddCatalog from './components/AddCatalog';
import InventoryPage from './components/InventoryPage';
import Expenses from './components/Expenses';
import Items from './components/Items';
import AppsPage from './components/AppsPage';
import Reports from './components/Reports';
import ResetPassword from './components/ResetPassword';
import Dashboard from './components/Dashboard';
import Platforms from './components/Platforms';
import Orders from './components/Orders';
import Menu from './components/Menu';
import Branches from './components/Branches';
import Profile from './components/Profile';
import Setup2FA from './components/Setup2FA';
import UserList from './components/Admin/Users/UserList';
import StoreBranchOverview from './components/StoreBranchOverview';
import AddNewStore from './components/AddNewStore';
import AddNewBranch from './components/AddNewBranch';
import ManageBranches from './components/ManageBranches';
import AppManagement from './components/AppManagement';
import AddApp from './components/AddApp';
import EditStore from './components/EditStore';
import AdminAppManagement from './components/Admin/AppManagement/AdminAppManagement';
import AdminAddApp from './components/Admin/AppManagement/AdminAddApp';
import AdminAddRoutes from './components/Admin/AppManagement/AdminAddRoutes';
import AdminEditApp from './components/Admin/AppManagement/AdminEditApp';
import ViewOrderDetails from './components/ViewOrderDetails';
import ProductListing from './components/ProductListing';
import AddProduct from './components/AddProduct';
import AddItem from './components/AddItem';
import AddCategory from './components/AddCategory';
import CategoryOverview from './components/CategoryOverview';

const PrivateRoute = ({ children, adminOnly }) => {
  const token = localStorage.getItem('token');
  const userType = JSON.parse(atob(token.split('.')[1])).user_type;

  // Redirect based on token type
  if (!token) return <Navigate to="/login" replace />;
  if (adminOnly && userType === 'admin') return <Navigate to="/admin/dashboard" replace />;
  return children;
};

function App() {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<UserLogin />} />
        <Route path="/register" element={<Register />} />
        {/* <Route path="/login" element={<UserLogin />} /> */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/admin/login" element={<AdminLogin />} />

        {/* Admin Dashboard */}
        <Route
          path="/admin/dashboard"
          element={<PrivateRoute adminOnly><AdminDashboard /></PrivateRoute>}
        />
        <Route
          path="/admin/user-list"
          element={<PrivateRoute adminOnly><UserList /></PrivateRoute>}
        />
        <Route
          path="/admin/available-apps"
          element={<PrivateRoute adminOnly><AdminAppManagement /></PrivateRoute>}
        />
        <Route
          path="/admin/add-app"
          element={<PrivateRoute adminOnly><AdminAddApp /></PrivateRoute>}
        />
         <Route
          path="/admin/manage-routes/:appId"
          element={<PrivateRoute adminOnly><AdminAddRoutes /></PrivateRoute>}
        />
        <Route
          path="/admin/edit-app"
          element={<PrivateRoute adminOnly><AdminEditApp /></PrivateRoute>}
        />



        {/* Protected Routes */}
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/platforms" element={<PrivateRoute><Platforms /></PrivateRoute>} />
        <Route path="/orders" element={<PrivateRoute><Orders /></PrivateRoute>} />
        <Route path="/catalog-overview" element={<PrivateRoute><CatalogOverview /></PrivateRoute>} />
        <Route path="/category-overview" element={<PrivateRoute><CategoryOverview /></PrivateRoute>} />
        <Route path="/store&branches-overview" element={<PrivateRoute><StoreBranchOverview /></PrivateRoute>} />
        <Route path="/inventory" element={<PrivateRoute><InventoryPage /></PrivateRoute>} />
        <Route path="/products-listing" element={<PrivateRoute><ProductListing /></PrivateRoute>} />
        <Route path="/expenses" element={<PrivateRoute><Expenses /></PrivateRoute>} />
        <Route path="/reports" element={<PrivateRoute><Reports /></PrivateRoute>} />
        <Route path="/catalog/items-list" element={<PrivateRoute><Items /></PrivateRoute>} />
        <Route path="/catalog/apps-list" element={<PrivateRoute><AppsPage /></PrivateRoute>} />
        <Route path="/orders/:orderId" element={<PrivateRoute><ViewOrderDetails /></PrivateRoute>} />
        <Route path="/apps-management" element={<PrivateRoute><AppManagement /></PrivateRoute>} />
        <Route path="/add-app" element={<PrivateRoute><AddApp /></PrivateRoute>} />
        <Route path="/add-category" element={<PrivateRoute><AddCategory /></PrivateRoute>} />
        <Route path="/add-item" element={<PrivateRoute><AddItem /></PrivateRoute>} />
        <Route path="/add-product" element={<PrivateRoute><AddProduct /></PrivateRoute>} />
        <Route path="/edit-catalog" element={<PrivateRoute><EditCatalog /></PrivateRoute>} />
        <Route path="/add-catalog" element={<PrivateRoute><AddCatalog /></PrivateRoute>} />
        <Route path="/add-store" element={<PrivateRoute><AddNewStore /></PrivateRoute>} />
        <Route path="/edit-store" element={<PrivateRoute><EditStore /></PrivateRoute>} />
        <Route path="/add-branch" element={<PrivateRoute><AddNewBranch /></PrivateRoute>} />
        <Route path="/menu" element={<PrivateRoute><Menu /></PrivateRoute>} />
        <Route path="/branches" element={<PrivateRoute><Branches /></PrivateRoute>} />
        <Route path="/manage-branches" element={<PrivateRoute><ManageBranches /></PrivateRoute>} />
        <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route path="/setup-2fa" element={<PrivateRoute><Setup2FA /></PrivateRoute>} />

        {/* Catch-all for undefined routes */}
        <Route path="*" element={<h1>404 - Page Not Found</h1>} />
      </Routes>
    </Router>
  );
}

export default App;
