import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardText, MDBBtn } from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';
import TopNavbar from '../../TopNavbar';
import SideNavbar from '../../SideNavbar';
import { api } from '../../../services';

function AdminAppManagement() {
    const [availableApps, setAvailableApps] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchAvailableApps();
    }, []);

    const fetchAvailableApps = async () => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await api.get('/admin/available-apps', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setAvailableApps(response.data.apps || []);
        } catch (error) {
            console.error('Error fetching available apps:', error.message);
            alert('Failed to fetch apps. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddNewApp = () => {
        navigate('/admin/add-app');
    };

    const handleEditApp = (app) => {
        navigate('/admin/edit-app', { state: { app } });
    };

    const handleDeleteApp = async (appId) => {
        if (!window.confirm('Are you sure you want to delete this app?')) {
            return;
        }
        try {
            const token = localStorage.getItem('token');
            await api.delete(`/admin/available-apps/${appId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchAvailableApps();
        } catch (error) {
            console.error('Error deleting app:', error.message);
        }
    };

    const handleManageRoutes = (appId) => {
        navigate(`/admin/manage-routes/${appId}`);
    };

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4">Admin - Manage Apps</h2>
                    <div className="d-flex justify-content-end mb-4">
                        <MDBBtn color="primary" onClick={handleAddNewApp}>
                            Add New App
                        </MDBBtn>
                    </div>
                    {isLoading ? (
                        <p className="text-center">Loading apps...</p>
                    ) : (
                        availableApps.length === 0 ? (
                            <p className="text-center text-muted">No apps available. Click "Add New App" to get started.</p>
                        ) : (
                            <MDBRow>
                                {availableApps.map((app) => (
                                    <MDBCol className="mb-4" md="6" key={app._id}>
                                        <MDBCard className="h-100">
                                            <MDBCardBody>
                                                <MDBCardText>
                                                    <strong>{app.name}</strong>
                                                </MDBCardText>
                                                <p>{app.description}</p>
                                                <p>
                                                    <strong>Status:</strong> {app.status}
                                                </p>
                                                <div className="d-flex justify-content-between">
                                                    <MDBBtn
                                                        color="info"
                                                        outline
                                                        size="sm"
                                                        onClick={() => handleEditApp(app)}
                                                    >
                                                        Edit
                                                    </MDBBtn>
                                                    <MDBBtn
                                                        color="secondary"
                                                        outline
                                                        size="sm"
                                                        onClick={() => handleManageRoutes(app._id)}
                                                    >
                                                        Manage Routes
                                                    </MDBBtn>
                                                    <MDBBtn
                                                        color="danger"
                                                        outline
                                                        size="sm"
                                                        onClick={() => handleDeleteApp(app._id)}
                                                    >
                                                        Delete
                                                    </MDBBtn>
                                                </div>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                ))}
                            </MDBRow>
                        )
                    )}
                </MDBContainer>
            </div>
        </>
    );
}

export default AdminAppManagement;
