import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdb-react-ui-kit';
import { api } from '../services';

function AddItem() {
    const [itemData, setItemData] = useState({
        name: '',
        description: '',
        category: '',
        price: '',
        calories: '',
        imagePath: '',
        isVisible: true,
    });
    const navigate = useNavigate();

    const handleFieldChange = (field, value) => {
        setItemData((prev) => ({ ...prev, [field]: value }));
    };

    const handleSave = async () => {
        try {
            const token = localStorage.getItem('token');
            await api.post('/items', itemData, {
                headers: { Authorization: `Bearer ${token}` },
            });
            navigate('/items'); // Redirect to items page
        } catch (error) {
            console.error('Error saving item:', error.message);
        }
    };

    return (
        <MDBContainer>
            <MDBRow className="mt-4">
                <MDBCol md="6">
                    <MDBInput
                        label="Name"
                        value={itemData.name}
                        onChange={(e) => handleFieldChange('name', e.target.value)}
                    />
                </MDBCol>
                <MDBCol md="6">
                    <MDBInput
                        label="Description"
                        value={itemData.description}
                        onChange={(e) => handleFieldChange('description', e.target.value)}
                    />
                </MDBCol>
                <MDBCol md="6">
                    <MDBInput
                        label="Category"
                        value={itemData.category}
                        onChange={(e) => handleFieldChange('category', e.target.value)}
                    />
                </MDBCol>
                <MDBCol md="6">
                    <MDBInput
                        label="Price"
                        type="number"
                        value={itemData.price}
                        onChange={(e) => handleFieldChange('price', e.target.value)}
                    />
                </MDBCol>
                <MDBCol md="6">
                    <MDBInput
                        label="Calories"
                        type="number"
                        value={itemData.calories}
                        onChange={(e) => handleFieldChange('calories', e.target.value)}
                    />
                </MDBCol>
                <MDBCol md="6">
                    <MDBInput
                        label="Image Path"
                        value={itemData.imagePath}
                        onChange={(e) => handleFieldChange('imagePath', e.target.value)}
                    />
                </MDBCol>
                <MDBCol md="6">
                    <label>Visibility</label>
                    <select
                        className="form-select"
                        value={itemData.isVisible}
                        onChange={(e) => handleFieldChange('isVisible', e.target.value === 'true')}
                    >
                        <option value="true">Visible</option>
                        <option value="false">Not Visible</option>
                    </select>
                </MDBCol>
            </MDBRow>
            <MDBRow className="mt-4">
                <MDBCol>
                    <MDBBtn onClick={handleSave}>Save Item</MDBBtn>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

export default AddItem;
