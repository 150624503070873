import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardText, MDBBtn, MDBBadge } from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import { api } from '../services';

function AppManagement() {
    const [apps, setApps] = useState([]); // User-integrated apps
    const [availableApps, setAvailableApps] = useState([]); // Available apps for integration
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchApps();
    }, []);

    const fetchApps = async () => {
        try {
            const token = localStorage.getItem('token');

            // Fetch integrated apps
            const integratedAppsResponse = await api.get('/apps', {
                headers: { Authorization: `Bearer ${token}` },
            });
            const integratedApps = integratedAppsResponse.data.apps || [];
            setApps(integratedApps);

            // Fetch all available apps
            const availableAppsResponse = await api.get('/available-apps', {
                headers: { Authorization: `Bearer ${token}` },
            });
            const allAvailableApps = availableAppsResponse.data.availableApps || [];

            // Filter available apps
            const integratedAppIds = integratedApps.map((app) => app._id);
            const filteredAvailableApps = allAvailableApps.filter(
                (app) => !integratedAppIds.includes(app._id) && app.status !== 'Inactive'
            );
            setAvailableApps(filteredAvailableApps);
        } catch (error) {
            console.error('Error fetching apps:', error.message);
        }
    };

    const handleAddNewApp = (appId, isDemo = false) => {
        if (isDemo) {
            handleIntegrateDemoApp(appId);
        } else {
            navigate('/add-app', { state: { appId } });
        }
    };

    const handleIntegrateDemoApp = async (appId) => {
        try {
            const token = localStorage.getItem('token');
            await api.post('/apps/demo-integrate', { appId }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchApps(); // Refresh the apps list
        } catch (error) {
            console.error('Error integrating Demo App:', error.message);
        }
    };

    const handleDisconnectApp = async (appId, isDemo = false) => {
        try {
            const token = localStorage.getItem('token');
            const url = isDemo ? '/apps/demo' : `/apps/${appId}`;
            await api.delete(url, { headers: { Authorization: `Bearer ${token}` } });
            fetchApps(); // Refresh the apps list
        } catch (error) {
            console.error('Error disconnecting app:', error.message);
        }
    };

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4">Apps Integration</h2>

                    <h5>Available Apps</h5>
                    <MDBRow className="mb-4">
                        {availableApps.map((app) => (
                            <MDBCol md="6" key={app._id} className="mb-4">
                                <MDBCard className="h-100">
                                    <MDBCardBody>
                                        <MDBCardText>
                                            <strong>{app.name}</strong>
                                        </MDBCardText>
                                        <p>{app.description}</p>
                                        {app.status === 'Pending' ? (
                                            <MDBBadge color="warning">Pending Approval</MDBBadge>
                                        ) : (
                                            <MDBBtn
                                                color={app.isDemo ? 'warning' : 'info'}
                                                outline
                                                size="sm"
                                                onClick={() => handleAddNewApp(app._id, app.isDemo)}
                                            >
                                                {app.isDemo ? 'Explore Demo' : 'Integrate'}
                                            </MDBBtn>
                                        )}
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        ))}
                    </MDBRow>

                    <h5>Integrated Apps</h5>
                    <MDBRow>
                        {apps.map((app) => (
                            <MDBCol md="4" key={app._id} className="mb-4">
                                <MDBCard className="h-100">
                                    <MDBCardBody>
                                        <MDBCardText>
                                            <strong>{app.application}</strong>
                                        </MDBCardText>
                                        {app.isDemo && <p className="text-warning">Demo App</p>}
                                        {app.status === 'connected' ? (
                                            <MDBBadge color="success">Connected</MDBBadge>
                                        ) : (
                                            <MDBBadge color="warning">Pending Approval</MDBBadge>
                                        )}
                                        {app.status === 'connected' && (
                                            <MDBBtn
                                                color="danger"
                                                outline
                                                size="sm"
                                                onClick={() => handleDisconnectApp(app._id, app.isDemo)}
                                            >
                                                Disconnect
                                            </MDBBtn>
                                        )}
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        ))}
                    </MDBRow>
                </MDBContainer>
            </div>
        </>
    );
}

export default AppManagement;
