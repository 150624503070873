import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MDBContainer, MDBInput, MDBBtn } from 'mdb-react-ui-kit';
import SideNavbar from '../../SideNavbar';
import TopNavbar from '../../TopNavbar';
import { api } from '../../../services';

function AdminAddApp() {
    const [form, setForm] = useState({
        name: '',
        description: '',
        logo: '',
        apiUrl: '',
        integrationMethod: 'API_KEY',
        status: 'Active',
        credentialsTemplate: '', // JSON string input
    });
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    // Handle general form input changes
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setForm((prev) => ({ ...prev, [name]: value }));
    };

    // Handle Credentials Template changes
    const handleCredentialsTemplateChange = (e) => {
        const value = e.target.value;
        setForm((prev) => ({ ...prev, credentialsTemplate: value }));
        setError(''); // Reset error message
    };

    const validateAndParseJSON = (jsonString) => {
        try {
            return JSON.parse(jsonString);
        } catch (err) {
            return null;
        }
    };

    // Handle form submission
    const handleSubmit = async () => {
        if (!form.name || !form.apiUrl || !form.integrationMethod || !form.status) {
            setError('All fields are required.');
            return;
        }

        const parsedCredentialsTemplate = validateAndParseJSON(form.credentialsTemplate);
        if (form.credentialsTemplate && !parsedCredentialsTemplate) {
            setError('Invalid JSON format for Credentials Template.');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const payload = {
                ...form,
                credentialsTemplate: parsedCredentialsTemplate, // Send parsed JSON object
            };
            await api.post('/admin/available-apps', payload, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setSuccessMessage('App added successfully!');
            setError('');
            setForm({
                name: '',
                description: '',
                logo: '',
                apiUrl: '',
                integrationMethod: 'API_KEY',
                status: 'Active',
                credentialsTemplate: '',
            });
            setTimeout(() => navigate('/admin/available-apps'), 2000);
        } catch (error) {
            console.error('Error adding app:', error.message);
            setError('Failed to add app. Please try again.');
        }
    };

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2>Add New App</h2>
                    <MDBInput
                        label="App Name"
                        name="name"
                        value={form.name}
                        onChange={handleFormChange}
                        className="mb-3"
                        required
                    />
                    <MDBInput
                        label="Description"
                        name="description"
                        value={form.description}
                        onChange={handleFormChange}
                        className="mb-3"
                    />
                    <MDBInput
                        label="Logo URL"
                        name="logo"
                        value={form.logo}
                        onChange={handleFormChange}
                        className="mb-3"
                    />
                    <MDBInput
                        label="API URL"
                        name="apiUrl"
                        value={form.apiUrl}
                        onChange={handleFormChange}
                        className="mb-3"
                        required
                    />
                    <select
                        name="integrationMethod"
                        value={form.integrationMethod}
                        onChange={handleFormChange}
                        className="form-select mb-3"
                        required
                    >
                        <option value="API_KEY">API Key</option>
                        <option value="BEARER_TOKEN">Bearer Token</option>
                        <option value="NONE">None</option>
                    </select>
                    <MDBInput
                        label="Credentials Template (JSON)"
                        name="credentialsTemplate"
                        value={form.credentialsTemplate}
                        onChange={handleCredentialsTemplateChange}
                        className="mb-3"
                        placeholder='{ "clientId": "string", "clientSecret": "string" }'
                    />
                    <select
                        name="status"
                        value={form.status}
                        onChange={handleFormChange}
                        className="form-select mb-3"
                        required
                    >
                        <option value="Active">Active</option>
                        <option value="Coming Soon">Coming Soon</option>
                        <option value="Pending">Pending</option>
                        <option value="Inactive">Inactive</option>
                    </select>
                    {error && <div className="alert alert-danger">{error}</div>}
                    {successMessage && <div className="alert alert-success">{successMessage}</div>}
                    <MDBBtn color="primary" onClick={handleSubmit}>
                        Add App
                    </MDBBtn>
                </MDBContainer>
            </div>
        </>
    );
}

export default AdminAddApp;
