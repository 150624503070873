import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MDBContainer, MDBInput, MDBBtn, MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import SideNavbar from '../../SideNavbar';
import TopNavbar from '../../TopNavbar';
import { api } from '../../../services';

function AdminAddRoutes() {
    const { appId } = useParams(); // Get the app ID from the URL
    const [routes, setRoutes] = useState([]);
    const [routeForm, setRouteForm] = useState({
        name: '',
        method: 'GET',
        endpoint: '',
        headers: '{}',
        queryParams: '{}',
        bodyTemplate: '{}',
    });
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchRoutes();
    }, []);

    // Fetch existing routes for the app
    const fetchRoutes = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/admin/available-apps/${appId}/routes`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setRoutes(response.data.routes || []);
        } catch (error) {
            console.error('Error fetching routes:', error.message);
        }
    };

    // Handle form input changes for non-JSON fields
    const handleRouteFormChange = (e) => {
        const { name, value } = e.target;
        setRouteForm((prev) => ({ ...prev, [name]: value }));
    };

    // Handle JSON field changes
    const handleJsonFieldChange = (field, value) => {
        setRouteForm((prev) => ({
            ...prev,
            [field]: value,
        }));
        setError(''); // Reset error if any
    };

    // Validate and parse JSON before submitting
    const validateAndParseJSON = (jsonString, fieldName) => {
        try {
            return JSON.parse(jsonString || '{}');
        } catch (err) {
            setError(`Invalid JSON format in ${fieldName}.`);
            throw new Error(`Invalid JSON format in ${fieldName}`);
        }
    };

    // Submit new or updated route
    const handleAddOrUpdateRoute = async () => {
        if (!routeForm.name || !routeForm.method || !routeForm.endpoint) {
            setError('Route Name, Method, and Endpoint are required.');
            return;
        }

        try {
            const headers = validateAndParseJSON(routeForm.headers, 'Headers');
            const queryParams = validateAndParseJSON(routeForm.queryParams, 'Query Parameters');
            const bodyTemplate = validateAndParseJSON(routeForm.bodyTemplate, 'Body Template');

            const token = localStorage.getItem('token');
            await api.post(`/admin/available-apps/${appId}/routes`, {
                ...routeForm,
                headers,
                queryParams,
                bodyTemplate,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });

            setSuccessMessage('Route added/updated successfully!');
            setError('');
            setRouteForm({
                name: '',
                method: 'GET',
                endpoint: '',
                headers: '{}',
                queryParams: '{}',
                bodyTemplate: '{}',
            });
            fetchRoutes(); // Refresh the list of routes
        } catch (error) {
            console.error('Error adding/updating route:', error.message);
            setError('Failed to add/update route. Please try again.');
        }
    };

    // Delete a route
    const handleDeleteRoute = async (routeId) => {
        if (!window.confirm('Are you sure you want to delete this route?')) {
            return;
        }

        try {
            const token = localStorage.getItem('token');
            await api.delete(`/admin/available-apps/${appId}/routes/${routeId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchRoutes(); // Refresh the list of routes
        } catch (error) {
            console.error('Error deleting route:', error.message);
        }
    };

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2>Manage Routes for App</h2>

                    {/* Add/Update Route Form */}
                    <MDBCard className="mb-4">
                        <MDBCardBody>
                            <h5>Add or Update Route</h5>
                            <MDBInput
                                label="Route Name"
                                name="name"
                                value={routeForm.name}
                                onChange={handleRouteFormChange}
                                className="mb-3"
                                required
                            />
                            <select
                                name="method"
                                value={routeForm.method}
                                onChange={handleRouteFormChange}
                                className="form-select mb-3"
                                required
                            >
                                <option value="GET">GET</option>
                                <option value="POST">POST</option>
                                <option value="PUT">PUT</option>
                                <option value="DELETE">DELETE</option>
                            </select>
                            <MDBInput
                                label="Endpoint"
                                name="endpoint"
                                value={routeForm.endpoint}
                                onChange={handleRouteFormChange}
                                className="mb-3"
                                required
                            />
                            <MDBInput
                                label="Headers (JSON)"
                                name="headers"
                                value={routeForm.headers}
                                onChange={(e) => handleJsonFieldChange('headers', e.target.value)}
                                className="mb-3"
                            />
                            <MDBInput
                                label="Query Parameters (JSON)"
                                name="queryParams"
                                value={routeForm.queryParams}
                                onChange={(e) => handleJsonFieldChange('queryParams', e.target.value)}
                                className="mb-3"
                            />
                            <MDBInput
                                label="Body Template (JSON)"
                                name="bodyTemplate"
                                value={routeForm.bodyTemplate}
                                onChange={(e) => handleJsonFieldChange('bodyTemplate', e.target.value)}
                                className="mb-3"
                            />
                            {error && <p className="text-danger">{error}</p>}
                            {successMessage && <p className="text-success">{successMessage}</p>}
                            <MDBBtn color="primary" onClick={handleAddOrUpdateRoute}>
                                Save Route
                            </MDBBtn>
                        </MDBCardBody>
                    </MDBCard>

                    {/* List of Existing Routes */}
                    <h5>Existing Routes</h5>
                    <MDBRow>
                        {routes.map((route) => (
                            <MDBCol md="6" key={route._id} className="mb-4">
                                <MDBCard className="h-100">
                                    <MDBCardBody>
                                        <p>
                                            <strong>Name:</strong> {route.name}
                                        </p>
                                        <p>
                                            <strong>Method:</strong> {route.method}
                                        </p>
                                        <p>
                                            <strong>Endpoint:</strong> {route.endpoint}
                                        </p>
                                        <div className="d-flex justify-content-between">
                                            <MDBBtn
                                                color="danger"
                                                outline
                                                size="sm"
                                                onClick={() => handleDeleteRoute(route._id)}
                                            >
                                                Delete
                                            </MDBBtn>
                                        </div>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        ))}
                    </MDBRow>
                </MDBContainer>
            </div>
        </>
    );
}

export default AdminAddRoutes;
