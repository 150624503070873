import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
} from 'mdb-react-ui-kit';
import { api } from '../services';

function AddProduct() {
    const [productData, setProductData] = useState({
        nameEn: '',
        nameAr: '',
        descriptionEn: '',
        descriptionAr: '',
        productPrice: '',
        categoryId: '',
        imagePath: '',
        calories: '',
        isVisible: true,
        excludeBranches: '',
        productId: '',
        index: '',
    });

    const [imageFile, setImageFile] = useState(null); // Image file for upload
    const [branches, setBranches] = useState([]);
    const [storeId, setStoreId] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [selectedBranchId, setSelectedBranchId] = useState('');
    const [appId, setAppId] = useState(null);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchStoreId();
        fetchAppId();
    }, []);

    useEffect(() => {
        if (storeId) {
            fetchBranches();
        }
    }, [storeId]);

    const fetchStoreId = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get('/stores/my', {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (!response.data || !response.data.stores.length) {
                throw new Error('No stores found in the response');
            }

            setStoreId(response.data.stores[0]._id);
        } catch (error) {
            console.error('Error fetching store ID:', error.response?.data || error.message);
        }
    };

    const fetchAppId = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get('/apps', {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (!response.data || !Array.isArray(response.data.apps) || response.data.apps.length === 0) {
                throw new Error('No apps found in the response');
            }

            const firstApp = response.data.apps[0];
            setAppId(firstApp._id);
        } catch (error) {
            console.error('Error fetching App ID:', error.response?.data || error.message);
            setError('Failed to fetch App ID. Please try again.');
        }
    };

    const fetchBranches = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/stores/${storeId}/branches`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setBranches(response.data.branches || []);
        } catch (error) {
            console.error('Error fetching branches:', error.response?.data || error.message);
        }
    };

    const handleFieldChange = (field, value) => {
        setProductData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleImageUpload = async () => {
        if (!imageFile) {
            setError('Image file is required.');
            return null;
        }
    
        try {
            const formData = new FormData();
            formData.append('image', imageFile);
    
            const token = localStorage.getItem('token');
            const response = await api.post('/products/upload', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            return response.data.imagePath; // Backend should return the uploaded image path
        } catch (error) {
            console.error('Error uploading image:', error.response?.data || error.message);
            setError(
                `Failed to upload image. ${
                    error.response?.data?.message || 'Please try again later.'
                }`
            );
            return null;
        }
    };
    
    const handleSave = async () => {
        // Validate required fields before proceeding
        if (!productData.nameEn || !productData.productPrice || !productData.categoryId) {
            setError('Required fields are missing: Name (English), Price, or Category ID.');
            return;
        }
    
        const uploadedImagePath = await handleImageUpload();
        if (!uploadedImagePath) {
            return; // Image upload failed, error is already set
        }
    
        const payload = {
            productId: productData.productId || null, // Use null if productId is optional
            name: {
                en: productData.nameEn,
                ar: productData.nameAr,
            },
            description: {
                en: productData.descriptionEn,
                ar: productData.descriptionAr,
            },
            productPrice: parseFloat(productData.productPrice),
            categoryId: productData.categoryId,
            imagePath: uploadedImagePath, // Image path from the backend
            calories: parseInt(productData.calories) || 0,
            isVisible: productData.isVisible,
            excludeBranches: productData.excludeBranches
                ? productData.excludeBranches.split(',')
                : [],
            index: parseInt(productData.index) || 0,
        };
    
        try {
            const token = localStorage.getItem('token');
            const response = await api.post('/products', payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            setSuccessMessage('Product added successfully.');
            setTimeout(() => navigate('/products-listing'), 2000);
        } catch (error) {
            console.error('Error adding product:', error.response?.data || error.message);
            setError(
                `Failed to add product. ${
                    error.response?.data?.message || 'Please try again later.'
                }`
            );
        }
    };
    
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImageFile(file);
    };

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4">Add Product</h2>
                    <MDBRow>
                        <MDBCol md="6" className="mb-3">
                            <label>Select Branch</label>
                            <MDBDropdown>
                                <MDBDropdownToggle color="light">
                                    {selectedBranch || 'Select Branch'}
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    {branches.map((branch) => (
                                        <MDBDropdownItem
                                            key={branch._id}
                                            onClick={() => {
                                                setSelectedBranch(branch.name);
                                                setSelectedBranchId(branch._id);
                                            }}
                                        >
                                            {branch.name}
                                        </MDBDropdownItem>
                                    ))}
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>
                        <MDBCol md="6" className="mb-3">
                            <label>Upload Image</label>
                            <input type="file" className="form-control" onChange={handleImageChange} />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                        <MDBCol md="6" className="mb-3">
                            <MDBInput
                                label="Product Name (English)"
                                value={productData.nameEn}
                                onChange={(e) => handleFieldChange('nameEn', e.target.value)}
                            />
                        </MDBCol>
                        <MDBCol md="6" className="mb-3">
                            <MDBInput
                                label="Product Name (Arabic)"
                                value={productData.nameAr}
                                onChange={(e) => handleFieldChange('nameAr', e.target.value)}
                            />
                        </MDBCol>
                        <MDBCol md="6" className="mb-3">
                            <MDBInput
                                label="Description (English)"
                                value={productData.descriptionEn}
                                onChange={(e) => handleFieldChange('descriptionEn', e.target.value)}
                            />
                        </MDBCol>
                        <MDBCol md="6" className="mb-3">
                            <MDBInput
                                label="Description (Arabic)"
                                value={productData.descriptionAr}
                                onChange={(e) => handleFieldChange('descriptionAr', e.target.value)}
                            />
                        </MDBCol>
                        <MDBCol md="6" className="mb-3">
                            <MDBInput
                                label="Product Price"
                                type="number"
                                value={productData.productPrice}
                                onChange={(e) => handleFieldChange('productPrice', e.target.value)}
                            />
                        </MDBCol>
                        <MDBCol md="6" className="mb-3">
                            <MDBInput
                                label="Category ID"
                                value={productData.categoryId}
                                onChange={(e) => handleFieldChange('categoryId', e.target.value)}
                            />
                        </MDBCol>
                        <MDBCol md="6" className="mb-3">
                            <MDBInput
                                label="Calories"
                                type="number"
                                value={productData.calories}
                                onChange={(e) => handleFieldChange('calories', e.target.value)}
                            />
                        </MDBCol>
                        <MDBCol md="6" className="mb-3">
                            <MDBInput
                                label="Exclude Branches (Comma Separated)"
                                value={productData.excludeBranches}
                                onChange={(e) =>
                                    handleFieldChange('excludeBranches', e.target.value)
                                }
                            />
                        </MDBCol>
                        <MDBCol md="6" className="mb-3">
                            <MDBInput
                                label="Product ID"
                                value={productData.productId}
                                onChange={(e) => handleFieldChange('productId', e.target.value)}
                            />
                        </MDBCol>
                        <MDBCol md="6" className="mb-3">
                            <MDBInput
                                label="Index"
                                type="number"
                                value={productData.index}
                                onChange={(e) => handleFieldChange('index', e.target.value)}
                            />
                        </MDBCol>
                        <MDBCol md="6" className="mb-3">
                            <label>Visibility</label>
                            <select
                                className="form-select"
                                value={productData.isVisible}
                                onChange={(e) =>
                                    handleFieldChange('isVisible', e.target.value === 'true')
                                }
                            >
                                <option value="true">Visible</option>
                                <option value="false">Not Visible</option>
                            </select>
                        </MDBCol>
                    </MDBRow>

                    {error && <p className="text-danger mt-3">{error}</p>}
                    {successMessage && <p className="text-success mt-3">{successMessage}</p>}

                    <MDBRow className="mt-4">
                        <MDBCol>
                            <MDBBtn
                                color="secondary"
                                outline
                                onClick={() => navigate('/product-listing')}
                            >
                                Cancel
                            </MDBBtn>
                            <MDBBtn color="primary" className="ms-2" onClick={handleSave}>
                                Save
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </>
    );
}

export default AddProduct;
