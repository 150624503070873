import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdb-react-ui-kit';
import { api } from '../services';

function AddCategory() {
    const navigate = useNavigate();
    const [apps, setApps] = useState([]);
    const [selectedApp, setSelectedApp] = useState(null);
    const [categoryDetails, setCategoryDetails] = useState({
        nameAr: '',
        nameEn: '',
        index: '',
        excludeBranches: '',
    });
    const [feedback, setFeedback] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Fetch apps on component mount
    useEffect(() => {
        const fetchApps = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await api.get('/apps', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setApps(response.data.apps || []);
            } catch (err) {
                console.error('Error fetching apps:', err.message);
            }
        };
        fetchApps();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCategoryDetails({ ...categoryDetails, [name]: value });
    };

    const handleAddCategory = async () => {
        if (!selectedApp) {
            setFeedback('Please select an app.');
            return;
        }
    
        if (!categoryDetails.nameAr.trim() || !categoryDetails.nameEn.trim() || !categoryDetails.index) {
            setFeedback('Please fill in all required fields.');
            return;
        }
    
        setIsLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await api.post(
                `/apps/${selectedApp._id}/categories`, // API call to backend
                {
                    appId: selectedApp._id,
                    nameAr: categoryDetails.nameAr.trim(),
                    nameEn: categoryDetails.nameEn.trim(),
                    index: parseInt(categoryDetails.index, 10),
                    excludeBranches: categoryDetails.excludeBranches
                        ? categoryDetails.excludeBranches.split(',').map((branch) => branch.trim())
                        : [],
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
    
            setFeedback(`Category added successfully: ${response.data.message}`);
            setTimeout(() => navigate('/catalog-overview'), 2000);
        } catch (err) {
            console.error('Error adding category:', err.message);
            setFeedback(`Error: ${err.response?.data?.message || 'Failed to add category.'}`);
        } finally {
            setIsLoading(false);
        }
    };
    

    const handleAppSelect = (app) => {
        setSelectedApp(app);
        setFeedback('');
    };

    return (
        <MDBContainer fluid className="p-4 content-area">
            <h2 className="mb-4">Add Category</h2>

            <MDBRow className="mb-4">
                <MDBCol md="4">
                    <MDBDropdown>
                        <MDBDropdownToggle color="primary">
                            {selectedApp ? selectedApp.application : 'Select an Integrated App'}
                        </MDBDropdownToggle>
                        <MDBDropdownMenu>
                            {apps.map((app) => (
                                <MDBDropdownItem key={app._id} onClick={() => handleAppSelect(app)}>
                                    {app.application}
                                </MDBDropdownItem>
                            ))}
                        </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBCol>
            </MDBRow>

            <MDBRow>
                <MDBCol md="6">
                    <MDBInput
                        label="Name (Arabic)"
                        name="nameAr"
                        value={categoryDetails.nameAr}
                        onChange={handleInputChange}
                        className="mb-3"
                    />
                    <MDBInput
                        label="Name (English)"
                        name="nameEn"
                        value={categoryDetails.nameEn}
                        onChange={handleInputChange}
                        className="mb-3"
                    />
                    <MDBInput
                        label="Index"
                        name="index"
                        value={categoryDetails.index}
                        onChange={handleInputChange}
                        type="number"
                        className="mb-3"
                    />
                    <MDBInput
                        label="Exclude Branches (Comma Separated)"
                        name="excludeBranches"
                        value={categoryDetails.excludeBranches}
                        onChange={handleInputChange}
                        className="mb-3"
                    />
                </MDBCol>
            </MDBRow>

            {feedback && <div className={`mt-3 ${feedback.includes('successfully') ? 'text-success' : 'text-danger'}`}>{feedback}</div>}

            <MDBBtn color="primary" onClick={handleAddCategory} disabled={isLoading}>
                {isLoading ? 'Adding...' : 'Add Category'}
            </MDBBtn>
            <MDBBtn color="secondary" className="ms-2" onClick={() => navigate('/catalog-overview')}>
                Cancel
            </MDBBtn>
        </MDBContainer>
    );
}

export default AddCategory;
