import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput,
    MDBBtn,
    MDBIcon,
} from 'mdb-react-ui-kit';
import { api } from '../services';

function AddNewBranch() {
    const [branchName, setBranchName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [availability, setAvailability] = useState([]);
    const [breakHours, setBreakHours] = useState([]);
    const [holidayHours, setHolidayHours] = useState([]);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { storeId } = location.state || {}; // Get the store ID passed through navigation

    const handleAddAvailability = () => {
        setAvailability([
            ...availability,
            { day: '', start: '', end: '' },
        ]);
    };

    const handleAddBreakHour = () => {
        setBreakHours([
            ...breakHours,
            { day: '', start: '', end: '' },
        ]);
    };

    const handleAddHolidayHour = () => {
        setHolidayHours([
            ...holidayHours,
            { day: '', start: '', end: '' },
        ]);
    };

    const handleSave = async () => {
        if (!branchName || !address || availability.length === 0) {
            setError('Branch name, address, and availability are required.');
            return;
        }
    
        try {
            const token = localStorage.getItem('token');
            const payload = {
                name: { en: branchName, ar: 'فرع العليا' }, // Example Arabic name
                email,
                address,
                availability,
                breakHours,
                holidayHours,
            };
    
            const response = await api.post(`/stores/${storeId}/branches`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            });
    
            setSuccessMessage('Branch added successfully and saved in Jahez.');
            setError('');
            setTimeout(() => navigate(`/manage-branches/${storeId}`), 2000);
        } catch (err) {
            console.error('Error adding branch:', err.message);
            setError(err.response?.data?.message || 'Failed to add branch. Please try again.');
            setSuccessMessage('');
        }
    };    

    const handleCancel = () => {
        navigate(`/manage-branches/${storeId}`);
    };

    const handleFieldChange = (setter, index, field, value) => {
        setter((prev) => {
            const updated = [...prev];
            updated[index][field] = value;
            return updated;
        });
    };

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4">Add New Branch</h2>
                    <MDBCard>
                        <MDBCardBody>
                            <MDBRow className="mb-3">
                                <MDBCol md="6">
                                    <MDBInput
                                        label="Branch Name"
                                        id="branchName"
                                        type="text"
                                        value={branchName}
                                        onChange={(e) => setBranchName(e.target.value)}
                                    />
                                </MDBCol>
                                <MDBCol md="6">
                                    <MDBInput
                                        label="Email"
                                        id="email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mb-3">
                                <MDBCol md="12">
                                    <MDBInput
                                        label="Address"
                                        id="address"
                                        type="text"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </MDBCol>
                            </MDBRow>

                            <h5 className="mt-4">Branch Availability</h5>
                            {availability.map((item, index) => (
                                <MDBRow key={index} className="mb-3 align-items-center">
                                    <MDBCol md="4">
                                        <MDBInput
                                            label="Day"
                                            type="text"
                                            value={item.day}
                                            onChange={(e) =>
                                                handleFieldChange(setAvailability, index, 'day', e.target.value)
                                            }
                                        />
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <MDBInput
                                            label="Start Time"
                                            type="time"
                                            value={item.start}
                                            onChange={(e) =>
                                                handleFieldChange(setAvailability, index, 'start', e.target.value)
                                            }
                                        />
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <MDBInput
                                            label="End Time"
                                            type="time"
                                            value={item.end}
                                            onChange={(e) =>
                                                handleFieldChange(setAvailability, index, 'end', e.target.value)
                                            }
                                        />
                                    </MDBCol>
                                </MDBRow>
                            ))}
                            <MDBBtn color="info" size="sm" onClick={handleAddAvailability}>
                                <MDBIcon fas icon="plus-circle" className="me-2" />
                                Add Custom Day
                            </MDBBtn>

                            <h5 className="mt-4">Break Hours</h5>
                            {breakHours.map((item, index) => (
                                <MDBRow key={index} className="mb-3 align-items-center">
                                    <MDBCol md="4">
                                        <MDBInput
                                            label="Day"
                                            type="text"
                                            value={item.day}
                                            onChange={(e) =>
                                                handleFieldChange(setBreakHours, index, 'day', e.target.value)
                                            }
                                        />
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <MDBInput
                                            label="Start Time"
                                            type="time"
                                            value={item.start}
                                            onChange={(e) =>
                                                handleFieldChange(setBreakHours, index, 'start', e.target.value)
                                            }
                                        />
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <MDBInput
                                            label="End Time"
                                            type="time"
                                            value={item.end}
                                            onChange={(e) =>
                                                handleFieldChange(setBreakHours, index, 'end', e.target.value)
                                            }
                                        />
                                    </MDBCol>
                                </MDBRow>
                            ))}
                            <MDBBtn color="info" size="sm" onClick={handleAddBreakHour}>
                                <MDBIcon fas icon="plus-circle" className="me-2" />
                                Add Break Hour
                            </MDBBtn>

                            <h5 className="mt-4">Holiday Hours</h5>
                            {holidayHours.map((item, index) => (
                                <MDBRow key={index} className="mb-3 align-items-center">
                                    <MDBCol md="4">
                                        <MDBInput
                                            label="Day"
                                            type="text"
                                            value={item.day}
                                            onChange={(e) =>
                                                handleFieldChange(setHolidayHours, index, 'day', e.target.value)
                                            }
                                        />
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <MDBInput
                                            label="Start Time"
                                            type="time"
                                            value={item.start}
                                            onChange={(e) =>
                                                handleFieldChange(setHolidayHours, index, 'start', e.target.value)
                                            }
                                        />
                                    </MDBCol>
                                    <MDBCol md="4">
                                        <MDBInput
                                            label="End Time"
                                            type="time"
                                            value={item.end}
                                            onChange={(e) =>
                                                handleFieldChange(setHolidayHours, index, 'end', e.target.value)
                                            }
                                        />
                                    </MDBCol>
                                </MDBRow>
                            ))}
                            <MDBBtn color="info" size="sm" onClick={handleAddHolidayHour}>
                                <MDBIcon fas icon="plus-circle" className="me-2" />
                                Add Holiday Hour
                            </MDBBtn>

                            {error && <p className="text-danger mt-3">{error}</p>}
                            {successMessage && <p className="text-success mt-3">{successMessage}</p>}

                            <MDBRow className="mt-4">
                                <MDBCol>
                                    <MDBBtn color="secondary" outline onClick={handleCancel}>
                                        Cancel
                                    </MDBBtn>
                                    <MDBBtn color="primary" className="ms-2" onClick={handleSave}>
                                        Save
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </div>
        </>
    );
}

export default AddNewBranch;
