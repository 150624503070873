import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
} from 'mdb-react-ui-kit';
import { api } from '../services';

function AddApp() {
    const navigate = useNavigate();
    const location = useLocation();
    const appId = location.state?.appId; // Retrieve appId passed from AppManagement
    const [stores, setStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState('');
    const [appDetails, setAppDetails] = useState(null);
    const [appData, setAppData] = useState({
        clientId: '',
        clientSecret: '',
    });
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        if (!appId) {
            navigate('/apps-management'); // Redirect if appId is missing
        } else {
            fetchUserStores();
            fetchAppDetails();
        }
    }, [appId, navigate]);

    const fetchUserStores = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get('/stores/my', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setStores(response.data.stores || []);
        } catch (error) {
            console.error('Error fetching user stores:', error.message);
        }
    };

    const fetchAppDetails = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/available-apps/${appId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setAppDetails(response.data.app);
        } catch (error) {
            console.error('Error fetching app details:', error.message);
        }
    };

    const handleSave = async () => {
        if (!selectedStore || !appData.clientId || !appData.clientSecret) {
            setError('Store, Client ID, and Client Secret are required.');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const payload = {
                appId,
                storeId: selectedStore, // Include selected storeId
                clientId: appData.clientId,
                clientSecret: appData.clientSecret,
            };

            console.log('Submitting Payload:', payload);

            await api.post('/apps', payload, {
                headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            });

            setSuccessMessage('App integrated successfully!');
            setTimeout(() => navigate('/apps-management'), 2000);
        } catch (err) {
            console.error('Error integrating app:', err.response?.data || err.message);
            setError(err.response?.data?.message || 'Failed to integrate app.');
        }
    };

    const handleFieldChange = (field, value) => {
        setAppData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    return (
        <>
            <TopNavbar />
            <div className="d-flex dashboard-layout">
                <SideNavbar />
                <MDBContainer fluid className="p-4 content-area flex-grow-1">
                    <h2 className="mb-4">Integrate App</h2>
                    {appDetails && (
                        <>
                            <h4>{appDetails.name}</h4>
                            <p>{appDetails.description}</p>
                        </>
                    )}
                    <MDBRow>
                        <MDBCol md="12" className="mb-3">
                            <label className="form-label">Select Store</label>
                            <MDBDropdown>
                                <MDBDropdownToggle color="light">
                                    {selectedStore
                                        ? stores.find((store) => store._id === selectedStore)?.name
                                        : 'Select a Store'}
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    {stores.map((store) => (
                                        <MDBDropdownItem
                                            key={store._id}
                                            onClick={() => setSelectedStore(store._id)}
                                        >
                                            {store.name}
                                        </MDBDropdownItem>
                                    ))}
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>
                        <MDBCol md="12" className="mb-3">
                            <MDBInput
                                label="Client ID"
                                type="text"
                                value={appData.clientId}
                                onChange={(e) => handleFieldChange('clientId', e.target.value)}
                            />
                        </MDBCol>
                        <MDBCol md="12">
                            <MDBInput
                                label="Client Secret"
                                type="text"
                                value={appData.clientSecret}
                                onChange={(e) => handleFieldChange('clientSecret', e.target.value)}
                            />
                        </MDBCol>
                    </MDBRow>
                    {error && <p className="text-danger mt-3">{error}</p>}
                    {successMessage && <p className="text-success mt-3">{successMessage}</p>}
                    <MDBRow className="mt-4">
                        <MDBCol>
                            <MDBBtn color="secondary" outline onClick={() => navigate('/apps-management')}>
                                Cancel
                            </MDBBtn>
                            <MDBBtn color="primary" className="ms-2" onClick={handleSave}>
                                Save
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </>
    );
}

export default AddApp;
